@import "pages/lvp-category";
@import "pages/lvp-detail";
@import "pages/account";

.blog-page .pagination>li>a,
.blog-page .pagination>li>span {
  color: #ffffff;
  background-color: transparent;
  font-weight: normal;
}
.blog-page .pagination>li.active>a, .blog-page .pagination>li.active>a:hover, .blog-page .pagination>li>a:hover, .blog-page .pagination>li>span:hover, .blog-page .pagination>li.active>a, .blog-page .pagination>li.active>a:hover, .blog-page .pagination>li>a, .blog-page .pagination>li>span {
  border-color: transparent;
}

.blog-page .pagination>li.active>a, .blog-page .pagination>li.active>a:hover, .blog-page .pagination>li>a:hover, .blog-page .pagination>li>span:hover {
  color: #ffffff;
  background-color: #000000;
}


//product carousel defaults
.carousel--product-film,
.carousel--blog,
.carousel--product,
.module-block  .lvp--tray,
.carousel--product-film-offset {
  position: relative;
  .slick-list {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .slick-track {
    display: flex;
  }
  .carousel__item {
    margin-right: 7.5px;
    margin-left: 7.5px;
  }
  .slick-arrow {
    height: 100%;
    padding: 5px;
    z-index: 1;
    &.slick-disabled {
      opacity: 0;
    }
  }

  .fullwidth .col-sm-0 & {
    padding-left: 15px;
    padding-right: 15px;
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }

    //commenting out for now pending testing
    @include breakpoint-sm {
      padding-left: 30px;
      padding-right: 30px;

      //.slick-prev {
      //  left: 0;
      //}
      //.slick-next {
      //  right: 0;
      //}

    }

    @include breakpoint-custom(1440px) {
      //padding-left: 30px;
      //padding-right: 30px;
      //.slick-prev {
      //  left: 0;
      //}
      //.slick-next {
      //  right: 0;
      //}
    }

  }

}
@include breakpoint-sm {
  .fullwidth .col-sm-0 .section__title-tray {
    padding: 0 30px;
  }
}
