.blog-page {
  h1 {
    font-size: 58px;
    padding-bottom: 20px;
    color: #fff;
    border-bottom: 1px solid #fff;
    font-weight: 300;
    margin: 50px 0;
    line-height: 1.1;

    @include breakpoint-sm {
      padding-bottom: 31px;
      font-size: 65px;
    }
    @include breakpoint-md {
      font-size: 82px;
    }
  }
  .blog-header {
    margin-bottom: 40px;
    h2 {
      margin-bottom: 0;
    }
    .meta-entry {
      font-size: 12px;
      color: #fff;
    }
  }
  .sidebar__content {
    .tag-line {
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
      color: #7d7d7d;
    }
    ul {
      text-transform: lowercase;
    }
  }
  .sidebar__search {
    margin-bottom: 70px;

    form {
      display: flex;
      flex-wrap: wrap;

      input {
        background: transparent;
        border: 1px solid #fff;
        height: 40px;
        padding: 9px;
        line-height: 40px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;

        &, &::placeholder {
          color: #fff;
        }
      }

      button {
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      }
    }
    @include breakpoint-md {
      margin-bottom: 0;
    }
  }
  .blog-content__image {
    margin-left: -15px;
    margin-right: -15px;
    span {
      display: block;
      height: 250px;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all .3s;
      position: relative;
      background-position: center;
    }
    @include breakpoint-md {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .blog-entry {
    border-bottom: 0;
  }
  .container--blog {
    max-width: 1170px !important;
  }
  .blog-month {
    margin: 0 0 20px;
    font-size: 60px;
    line-height: 1.1;
    @include breakpoint-sm {
      font-size: 62px;
    }
  }
}
.blog-detail-page {
  .blog-header h1 {
    font-size: 55px;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 15px;
    margin-top: 0;
    font-weight: 300;
    @include breakpoint-sm {
      font-size: 65px;
    }
  }
  .blog-content__image {
    margin-left: 0;
    margin-right: 0;
  }
  .blog-content__image span {
    height: 50vh;
    min-height: 0;
    margin-bottom: 30px;
  }
  .blog-content__copy .text {
    font-size: 18px;
    line-height: 27px;

    p {
      margin-bottom: 20px;
    }
  }
  .blog-content__share {
    display: flex;
    justify-content: space-between;
    max-width:300px;
    margin:80px auto 0 !important;
    > a, > button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 53px;
      width: 53px;
      border: 2px solid #fff;
      position: relative;
      transition: all .3s;
      color: #fff;
      background: transparent;

      &:hover {
        background: #fff;
        color: #000;
        text-decoration: none;
      }

      i {
        font-size: 23px;
      }

    }
    button .share-options {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #000;
      height: 100%;
      align-items: center;
      padding: 10px;
      margin-left: 2px;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease;

      a, .st-custom-button {
        color: #fff;
        padding: 8px;
        display: inline-block;
        cursor: pointer;
      }

      a:hover, .st-custom-button:hover {
        color: #999;
      }
    }
    button:hover .share-options {
      left: 100%;
      opacity: 1;
      visibility: visible;
    }
    @include breakpoint-sm {
      margin: 80px 0 0 !important;
    }
  }
}
