.lvp__content--full-screen {
  padding: 0;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.lvp__content-container {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;

  h1 {
    text-transform: none;
    margin-bottom: 5px;
    line-height: 1;
    margin-top: 0;
  }
}
.lvp__copy {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}

.lvp__feature-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 67px);
  min-height: 400px;
  margin-bottom: 30px;
}
.lvp__attributes, .lvp__content-container h1 {
  flex: 0 0 100%;
  max-width: 100%;
}
.lvp__attributes {
  display: flex;
  margin-bottom: 25px;
  font-size: 12px;
  line-height: 17px;

  >div {
    position: relative;
  }

  div+div {
    margin-left: 5px;
    &:before {
      content:'|';
      display: inline-block;
      margin-right: 5px;
    }
  }

}
.lvp__title, .lvp__attributes {
  padding: 0 15px;
}
.lvp__item {
  border-top: 1px solid #fff;
  padding-top: 20px;
  margin-top: 20px;
  h3 {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}
.lvp__item-related .lvp--grid, .lvp--related {
  grid-row-gap: 30px;
  h3 {
    line-height: 20px;
    margin-bottom: 5px;
    text-transform: none;
  }
  p, .director {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
  }
}
.lvp__item-blog {
  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      display: flex;
      margin-bottom: 20px;
      &,a {
        color: #999;
      }
    }
    li::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: "\f08e";
      margin-right: 10px;
      color: #685f77;
    }
  }
}

.lvp__icon-grid {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  text-align: center;
  color: #ccc;
  margin-top: 30px;
  grid-row-gap: 20px;

  &__item {
    padding: 10px;
  }

  &__item img {
    margin-bottom: 10px;
    max-height: 50px;
    object-fit: fill;
  }

  &__item span {
    font-size: 11px;
    color: #ccc;
  }
}

.lvp__item-tabs {
  p {
    margin-bottom: 20px;
  }
  .active .btn--outline, .btn--outline:hover {
    background: #fff;
    color: #000;
  }
  .btn--outline {
    border-color: #fff;
    color: #fff;
  }
  .panel-group {
    border-top: 1px solid #3b3b3b;
    padding-top: 16px;
  }
}

.panel--response {
  border: 0;
  background-color: transparent;
  > .panel-heading {
    background-color: transparent;
    border-color: transparent;
  }
  .panel-body--response {
    border-top: 0 !important;
  }
  +.panel--response {
    border-top: 1px solid #3b3b3b;
    padding-top: 16px;
  }
}
.panel-title--question {
  line-height: 1.3;
  strong, >a[aria-expanded=true] {
    color: #fff !important;
  }
  strong {
    text-transform: uppercase;
    margin-right: 10px;
  }
  >a {
    position: relative;
    text-decoration: none !important;
    display: block;
    &:after {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;

      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: "\f067";

      position: absolute;
      right: -15px;
      top: 0;
    }
  }
  >a[aria-expanded=true]:after {
    content: "\f068";
  }
  >a>span {
    display: block;
  }
  .question {
    margin-bottom: 20px;
  }

}
.panel-body--response span {
  display: block;
  +span {
    margin-top: 20px;
  }
}
@include breakpoint-max($screen-xs-max) {
  .lvp__attributes {
    order: 1;
  }
  .lvp__sidebar {
    order: 2;
  }
  .lvp__copy {
    order: 3;
  }
  .lvp--related {
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }
}
@include breakpoint-sm {
  .lvp--related {
    grid-column-gap: 30px;
  }
}
@include breakpoint-md {
  .lvp__copy {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
