.header  {
  padding: 10px 0 6px;
  &.header-fixed-shrink {
    padding: 0;
  }
  .navbar-header .navbar-brand {
    padding: 0 0 0 15px;
    margin: 0;
  }
}
