:focus {
  outline: 0 !important;
}

html {
  font-size: 100%;
}

.section__sub-title {
  padding: 0 15px;

  h3 {
    font-size: 20px;
    line-height: 1.4;
    margin: 0 0 15px;
  }
}

@include breakpoint-sm {
  .fullwidth .col-sm-0 .section__sub-title {
    padding: 0 30px;
  }
}


.section__title:has(+.section__sub-title) {
  margin-bottom: 5px;
}
@include breakpoint-max($screen-xs-max) {
  .section__title-tray .btn--view-more:not(.tp-caption__button-group.btn):not(.tp-caption__button-group.cookie-action__button) {
    width: fit-content!important;
  }
}

