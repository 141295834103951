.nav--tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  &:before,&:after {
    display: none;
  }
  li {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  li+li {
    margin-top: 15px;
  }
  @include breakpoint-md {
    li {
      flex-basis: 0;
      flex-grow: 1;
    }
    li+li {
      margin-left: 25px;
      margin-top: 0;
    }
  }
}
