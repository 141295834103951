.account-page .login-form form .btn, .account-page .main .btn {
  background: #db2827;
  color: #fff !important;
  border-radius: 4px;

  &:hover {
    background: rgba(219,40,39,.8);
    color: #fff;
  }

  &.btn--gray {
    background: #cccccc;
    color: #000 !important;

    &:hover {
      background: rgba(204,204,204,.8);
      color: #000 !important;
    }
  }
}
