.table--striped {
  border: 1px solid #999;

  * {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  }

  > thead > tr > th {
    text-transform: uppercase;
    border-bottom: 1px solid #fff;
    padding: 15px 10px !important;
    font-weight: 700;
  }

  > tbody > tr > td {
    border-bottom: 1px solid #999;
    padding: 15px 10px;

    &, a {
      color: #999 !important;
    }
  }

  > tbody > tr:nth-of-type(even), > thead > tr {
    background-color: #575253;
  }

  > thead > tr > th, > tbody > tr > td:first-child {
    &, a {
      color: #fff !important;
    }
  }



}
