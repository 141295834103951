.lvp-category-page {
  .main {
    padding-top: 40px;
  }
  .header {
    background-color: #171515;
  }
  .lvp__title {
    text-transform: none;
    margin-top: 0;
  }
  .lvp--grid__entry-title {
    margin-bottom: 5px;
  }
  .director {
    font-size: 12px;
    line-height: 12px;
  }
  .lvp--grid__description p {
    margin-bottom: 0;
  }
  .lvp--grid {
    padding: 0 15px;
  }
}
