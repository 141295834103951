.lvp__btn-group, .lvp__copy, .lvp__sidebar .lvp__btn-group {
  .btn, .btn--default {
    width: 100%;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 15px;
    font-family: 'Nunito Sans';
    letter-spacing: 1px;
    padding: 0;
    border-radius: 0;
    line-height: normal;
    height: 60px;
    background: no-repeat;
    border: 2px solid #ccc;
    background: #ccc;
    color: #000;
    transition: all .4s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .day-rental {
      color: #7f7f7f;
      text-transform: none !important;
      margin-top: 2px;
    }

    +.btn {
      margin-top: 25px;
    }

    &:hover {
      opacity: .8;
      background: #ccc;
      color: #000;
    }


  }

  .btn--outline {
    border: 2px solid #ccc;
    background: transparent;
    color: #ccc;

    //&:hover {
    //  background:#ccc;
    //  color: #000;
    //}
  }
  .btn--theater {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 18px;

    i {
      font-size: 35px;
    }
  }
}
